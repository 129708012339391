.btnDefault {
    color: #FFFFFF;
    padding-inline: 8px;
}

.btnDefault:hover {
    color: #91d5ff;
}

.md-search {
    color: #FFFFFF;
    font-size: 1.2em;
}


@media screen and (max-width: 576px) { 
    .top-price-btn {
        display: none;
    }
}

@media screen and (min-width: 576px) { 
    .top-price-btn {
        padding-inline: 12px;
        background: #F6FBF7;
        border-radius: 8px;
        height: 40px;
        margin-right: 24px;
    }
    
}

